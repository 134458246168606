const MEASUREMENT_SYSTEMS_DEF = {
  // Metric system
  metric: {
    length: { unit: 'mm', decimals: 0, from: v => v, to: v => v },
    temperature: { unit: '°C', decimals: 0, from: v => v, to: v => v },
    electricPower: { unit: 'W', decimals: 1, from: v => v, to: v => v },
    heatingPower: { unit: 'W', decimals: 0, from: v => v, to: v => v },
    waterFlow: { unit: 'l/h', decimals: 0, from: v => v, to: v => v },
    airFlow: { unit: 'm³/h', decimals: 0, from: v => v, to: v => v },
    waterPressureDrop: { unit: 'kPa', decimals: 2, from: v => v, to: v => v },
    airPressureDrop: { unit: 'Pa', decimals: 0, from: v => v, to: v => v },
    weight: { unit: 'kg', decimals: 1, from: v => v, to: v => v },
    volume: { unit: 'l', decimals: 2, from: v => v, to: v => v },
    revolution: { unit: 'rpm', decimals: 0, from: v => v, to: v => v }
  },

  // Imperial system
  imperial: {
    length: { unit: 'in', decimals: 1, from: v => 25.4 * v, to: v => v / 25.4 },
    temperature: {
      unit: '°F',
      decimals: 0,
      from: v => ((v - 32) * 5) / 9,
      to: v => (v * 9) / 5 + 32
    },
    electricPower: { unit: 'W', decimals: 1, from: v => v, to: v => v },
    heatingPower: {
      unit: 'BTU/h',
      decimals: 0,
      from: v => 0.29307027777777777777777777777778 * v,
      to: v => v / 0.29307027777777777777777777777778
    },
    waterFlow: {
      unit: 'GPM',
      decimals: 2,
      from: v => 227.12471428571428571428571428571 * v,
      to: v => v / 227.12471428571428571428571428571
    },
    airFlow: {
      unit: 'CFM',
      decimals: 0,
      from: v => 1.69901079552 * v,
      to: v => v / 1.69901079552
    },
    waterPressureDrop: {
      unit: 'inAq',
      decimals: 1,
      from: v => 0.249082 * v,
      to: v => v / 0.249082
    },
    airPressureDrop: {
      unit: 'inAq',
      decimals: 2,
      from: v => 249.082 * v,
      to: v => v / 249.082
    },
    weight: {
      unit: 'lb',
      decimals: 1,
      from: v => 0.45359237 * v,
      to: v => v / 0.45359237
    },
    volume: {
      unit: 'gal',
      decimals: 2,
      from: v => 3.785411784 * v,
      to: v => v / 3.785411784
    },
    revolution: { unit: 'rpm', decimals: 0, from: v => v, to: v => v }
  }
}

const _getSystemQuantityDef = (system, quantity) => {
  const sq =
    MEASUREMENT_SYSTEMS_DEF[system] && MEASUREMENT_SYSTEMS_DEF[system][quantity]
  if (!sq)
    new AppProblem(500)
      .addExtraData(
        'dev_ms',
        `Measurement system '${system}' or quantity '${quantity}' not defined`
      )
      .throw()
  return sq
}

export const definedMeasurementSystems = Object.keys(MEASUREMENT_SYSTEMS_DEF)

export const getUnit = (system, quantity, value) => {
  const sq = _getSystemQuantityDef(system, quantity)
  return sq.unit
}

export const convertFrom = (system, quantity, value) => {
  const sq = _getSystemQuantityDef(system, quantity)
  return Array.isArray(value) ? value.map(v => sq.from(v)) : sq.from(value)
}

export const convertTo = (system, quantity, value, round = false) => {
  const sq = _getSystemQuantityDef(system, quantity)
  const to = round ? v => Number(sq.to(v).toFixed(sq.decimals)) : sq.to
  return Array.isArray(value) ? value.map(v => to(v)) : to(value)
}
