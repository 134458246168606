<template>
  <div class="flex flex-col w-full">
    <AppTopBar class="h-8 bg-primary justify-between content-center">
      <h2
        class="text-center text-primary-content font-semibold flex-1 px-2"
        v-text="pageTitle"
      />
      <!-- <AppSpinner v-if="productsStore.isContentUpdating" size="sm" /> -->
      <ReportBug />
      <AppIcon
        v-if="netIcon"
        :path="netIcon.icon"
        class="h-5"
        :class="netIcon.class"
      />
      <AppLanguageSelect
        vertical="bottom"
        horizontal="left"
        size="xxs"
        class="mr-1 ml-2 mb-px"
      />
    </AppTopBar>
    <!-- <LayoutNavbar /> -->
    <!-- <LayoutFlash /> -->
    <!-- <LayoutEmailConfirmNotice /> -->
    <!-- <LayoutTopHeaderBar /> -->
    <div
      ref="container"
      class="container bg-white mx-auto shadow-lg flex-1 transition-all duration-200 delay-200"
    >
      <!-- <div class="sm:my-8 w-full mx-auto"> -->
      <!-- {{ routes }} -->
      <slot />
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'DefaultLayout',
}
</script>

<script setup>
import { ref, computed, onMounted, onBeforeMount, onBeforeUnmount } from 'vue'
import { useAppStore } from 'mc-gcommon/stores/app.js'
import { useLangStore } from 'mc-gcommon/stores/lang.js'
// import { useProductsStore } from '@/stores/products.js'
// import { useResizeStore } from 'mc-gcommon/stores/resize.js'
import ReportBug from 'mc-gcommon/components/layout/report-bug.vue'
import AppPageTitle from 'mc-gcommon/components/app/app-page-title.vue'
import AppButton from 'mc-gcommon/components/app/app-button.vue'
import AppIcon from 'mc-gcommon/components/app/app-icon.vue'
import AppSpinner from 'mc-gcommon/components/app/app-spinner.vue'
import AppTopBar from 'mc-gcommon/components/app/app-top-bar.vue'
import AppLanguageSelect from 'mc-gcommon/components/app/app-language-select.vue'
import { mdiWifi, mdiWifiOff } from '@mdi/js'

const appStore = useAppStore()
const langStore = useLangStore()
// const productsStore = useProductsStore()
// const resizeStore = useResizeStore()

const container = ref(null)
const parentel = ref(null)
const netIcon = computed(() => {
  const online = appStore.isOnline
  if (online === null) return null
  return {
    icon: online ? mdiWifi : mdiWifiOff,
    class: online ? 'fill-success-content' : 'fill-error-content',
  }
})

const pageTitle = computed(() => langStore.t(appStore.getPageTitle))

onBeforeMount(() => {
  // resizeStore.init()
})

onMounted(() => {
  // resizeStore.observe('container', container.value)
})

onBeforeUnmount(() => {
  // resizeStore.terminate()
})
</script>
