import { createRouter, createWebHistory } from 'vue-router'
import dbg from 'mc-gcommon/utils/debug.js'
import { useLangStore } from 'mc-gcommon/stores/lang.js'
import { useAuthStore } from 'mc-gcommon/stores/auth.js'
import { useClientEvent } from 'mc-gcommon/utils/client-event-hook.js'
import { envGetMcUrl } from 'mc-gcommon/utils/env.js'
// import Root from '@/root.vue'
import Page4xx from '@/pages/error/4xx-page.vue'
import Page401 from '@/pages/error/401-page.vue'
import Page404 from '@/pages/error/404-page.vue'
import supportRoutes from './support-routes.js'
import adminRoutes from './admin-routes.js'

const routes = [
  {
    path: '',
    name: 'home',
    component: () => import('@/pages/home-page.vue'),
    meta: {
      authRequired: false,
    },
  },
  {
    path: 'products',
    component: () => import('@/pages/products/products-page.vue'),
    children: [
      {
        path: '',
        name: 'products',
        component: () => import('@/pages/products/products-list-page.vue'),
      },
      {
        path: ':pid',
        name: 'product-detail',
        component: () => import('@/pages/products/product-detail-page.vue'),
      },
    ],
    meta: {
      authRequired: false,
      layout: 'products',
    },
  },
  {
    path: 'calculators',
    name: 'calculators',
    component: () => import('@/pages/calculators-page.vue'),
    meta: {
      authRequired: true,
      layout: 'calculators',
    },
  },
  {
    path: 'admin',
    component: () => import('@/pages/admin/admin-page.vue'),
    children: adminRoutes,
    meta: {
      authRequired: true,
    },
  },
  {
    path: 'support',
    component: () => import('@/pages/support/support-page.vue'),
    children: supportRoutes,
    meta: {
      authRequired: true,
      layout: 'conversations',
    },
  },
  {
    path: 'service-controller',
    name: 'service-controller',
    component: () => import('@/pages/service/service-controller-page.vue'),
    meta: {
      authRequired: true,
      permissions: 'toolbox:service:x',
      layout: 'service-controller',
      noFooter: true,
    },
  },
  {
    path: 'service-spare-parts',
    name: 'service-spare-parts',
    component: () => import('@/pages/service/service-spare-parts-page.vue'),
    meta: {
      authRequired: true,
      permissions: 'toolbox:service:r',
      layout: 'conversations',
    },
  },
  {
    path: 'service-ccupload',
    name: 'service-ccupload',
    component: () => import('@/pages/service/service-ccupload-page.vue'),
    meta: {
      authRequired: true,
      permissions: 'toolbox:service:x',
      layout: 'service-controller',
    },
  },
  {
    path: 'demo',
    name: 'demo',
    component: () => import('@/pages/demo/demo-page.vue'),
    meta: {
      // authRequired: true,
      // permissions: 'toolbox:service:r',
      layout: 'service-controller',
      noFooter: true,
    },
  },
  {
    path: 'settings',
    name: 'settings',
    component: () => import('@/pages/settings/settings-page.vue'),
    // meta: {
    //   authRequired: true,
    // },
  },
  {
    path: 'help',
    name: 'help',
    component: () => import('@/pages/help/help.vue'),
    meta: {
      authRequired: false,
    },
  },
  {
    path: 'playground',
    name: 'playground',
    component: () => import('@/pages/playground-page.vue'),
    meta: {
      authRequired: false,
    },
  },
  {
    path: 'email-confirm',
    name: 'email-confirm',
    component: () => import('@/pages/error/email-confirm-page.vue'),
  },
  {
    path: '401',
    name: 'unauthorized',
    component: Page401,
  },
  {
    path: '4xx',
    name: 'error',
    component: Page4xx,
    props: route => {
      console.log('******', route)
      return route.query
    },
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior,
  routes: [
    {
      path: '/',
      redirect: to => {
        const langStore = useLangStore()
        console.log('CATCH /', 'REDIRECT', langStore.locale)
        return `/${langStore.locale}`
      },
    },
    {
      path: '/:locale([a-z]{2})',
      // component: Root,
      children: [...routes /*...authRoutes*/],
    },
    {
      path: '/:locale(cs|en|de|it)/:pathMatch(.*)*',
      name: 'not-found',
      component: Page404,
    },
    {
      path: '/:pathMatch(.*)',
      redirect: to => {
        const langStore = useLangStore()
        console.log('CATCH', to, 'REDIRECT', '/' + langStore.locale + to.path)
        return `/${langStore.locale}${to.path}`
      },
    },
  ],
})

// router.beforeEach((to, from) => {
//   const langStore = useLangStore()
//   console.log('ROUTER LOCALE', to, from, langStore)
// })

const clientEvent = useClientEvent()

router.beforeEach((to, from) => {
  // console.log('BEFORE EACH', to, from)
})
router.afterEach((to, from) => {
  // console.log('AFTER EACH', to, from)
  clientEvent.page({ route: to })
})

// // Scroll to position after router transition finishes (see Root component too)
// let scrollAfterEnterResolve = null
// let scrollAfterEnterResolveValue = null

// export const scrollAfterEnter = () => {
//   console.log('SCROLL AFTER ENTER', scrollAfterEnterResolveValue)
//   if (scrollAfterEnterResolve)
//     scrollAfterEnterResolve(scrollAfterEnterResolveValue)
// }

async function scrollBehavior(to, from, savedPosition) {
  if (to && to.meta && to.meta.noScroll) return
  return new Promise((resolve, reject) => {
    console.log('SCROLL BEHAVIOR', to, from, savedPosition)

    let ret = { top: 0, left: 0 }

    if (to.hash) {
      // console.log('SCROLL TO HASH', to.hash)
      ret = { el: to.hash, behavior: 'smooth', top: 48 }
    } else if (savedPosition) {
      // console.log('SCROLL TO SAVED', savedPosition)
      ret = { ...savedPosition, behavior: 'smooth' }
    }

    // return new Promise((resolve, reject) => {
    //   setTimeout(() => resolve(ret), 5000)
    // })

    setTimeout(() => resolve(ret), 500)
  })
}

router.beforeEach(async (to, from) => {
  if (to.params.locale === from.params.locale) return
  const langStore = useLangStore()
  // console.log('ROUTE SET LOCALE', to.params.locale)
  await langStore.setLocale(to.params.locale)
})

router.beforeEach(async (to, from) => {
  dbg.logVerbose('[Middleware] AUTH', to.path, to.meta)
  const authStore = useAuthStore()

  const isPageProtected = authStore.isPageProtected(to.meta)
  dbg.logDebug('- isPageProtected', isPageProtected)

  if (!isPageProtected) return

  // protected page

  const isAuthenticated = authStore.isAuthenticated
  console.log('- isAuthenticated', isAuthenticated)
  if (!isAuthenticated) {
    const { locale } = useLangStore()
    return {
      name: 'unauthorized',
      params: { locale },
      query: { from: to.path },
    }
  }

  const confirmationPeriodExpired = authStore.confirmationPeriodExpired
  console.log('- confirmationPeriodExpired', confirmationPeriodExpired)
  if (confirmationPeriodExpired) {
    const authStore = useAuthStore()
    authStore.authConfirm(envGetMcUrl('app', 'toolbox') + to.fullPath)
  }

  const isGranted = authStore.isGranted(to.meta)
  console.log('- isGranted', isGranted)
  if (isGranted) return

  // access not granted from here further

  console.log('- redirect - not allowed', isGranted)
  return {
    name: 'unauthorized',
    /*params: { locale: to.params.locale },*/
    query: { from: to.name },
  }
})

export default router
