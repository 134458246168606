<template>
	<main class="hero h-full bg-base-200">
		<div class="hero-content flex-col max-w-4xl">
			<section class="text-center">
				<AppImg
					class="w-2/3 mx-auto mb-10"
					:src="appStore.appUrl('images/minib-full-logo.svg')"
				/>
				<h1 class="text-5xl font-bold" v-t="'page.401.title'" />
				<p class="pt-6" v-t="'page.401.text'" />
			</section>
			<AppTimer
				:tick="250"
				:timerStart="REDIRECT_TIMEOUT"
				:timerEnd="0"
				:run="run"
				class="w-1/2"
				@end="redirect"
			>
				<template #stopped>
					<div v-t="'page.401.waiting_for_network'" />
				</template>
				<template #end>
					<div v-t="'page.401.redirecting'" class="w-fit mx-auto" />
				</template>
				<template v-slot="{ timer, timerEnd, timerStart }">
					<progress
						class="progress progress-primary w-full"
						:value="timer"
						:max="timerStart"
					/>
				</template>
			</AppTimer>
		</div>
	</main>
</template>

<script>
	export default {
		name: '401Page'
	}
</script>

<script setup>
	import { ref, watch } from 'vue'
	import { useRoute } from 'vue-router'
	import { env, envGetMcUrl } from 'mc-gcommon/utils/env.js'
	import { useAppStore } from 'mc-gcommon/stores/app.js'
	import { useAuthStore } from 'mc-gcommon/stores/auth.js'
	import AppImg from 'mc-gcommon/components/app/app-img.vue'
	import AppTimer from 'mc-gcommon/components/app/app-timer.vue'

	const route = useRoute()
	const appStore = useAppStore()
	const authStore = useAuthStore()

	const REDIRECT_TIMEOUT = 3000

	const appURL = envGetMcUrl('app', env.APP_NAME)
	const fromURL = appURL + route.query.from

	const run = ref(false)

	function redirect() {
		authStore.authSignIn(fromURL)
	}

	watch(
		() => appStore.isOnline,
		to => (run.value = to),
		{ immediate: true }
	)
</script>
