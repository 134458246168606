<template>
	<h1>404</h1>
	<h2>Page not found (TODO)</h2>
	{{ route }}
</template>

<script>
	export default {
		name: '404Page'
	}
</script>

<script setup>
	import { useRoute } from 'vue-router'

	const route = useRoute()
</script>
