export default [
	{
		path: 'conversations',
		name: 'admin-conversations',
		component: () => import('@/pages/admin/admin-conversations-page.vue'),
		meta: {
			authRequired: true,
			permissions: 'toolbox:conversation:u',
		},
	},
	{
		path: 'conversation/:id',
		name: 'admin-conversation-detail',
		component: () => import('@/pages/admin/admin-conversation-detail-page.vue'),
		meta: {
			authRequired: true,
			permissions: 'toolbox:conversation:u',
			noScroll: true,
		},
	},
	{
		path: 'bugs',
		name: 'bugs',
		component: () => import('@/pages/admin/bugs-page.vue'),
		meta: {
			authRequired: true,
			permissions: 'auth:admin:r',
		},
	},
]
