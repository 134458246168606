import { defineStore } from 'mc-gcommon/utils/pinia'
import { restClient } from 'mc-gcommon/utils/api-client.js'

const STORE = 'users'

const EMPLOYEE_KINDS = ['hq', 'bykev']

export const useUsersStore = defineStore({
  id: STORE,

  state: () => ({
    users: {},
  }),

  getters: {
    employees: state =>
      Object.keys(state.users).reduce(
        (acc, id) => (
          EMPLOYEE_KINDS.includes(state.users[id].kind) &&
            (acc[id] = state.users[id]),
          acc
        ),
        {}
      ),
  },

  actions: {
    async fetchUser(id) {
      // console.log('$$$$ FETCH USER', id, this.users[id])
      if (!id || this.users[id]) return
      Object.assign(this.users, {
        [id]: {
          name: '?',
          kind: 'generic',
        },
      })
      try {
        const user = (await restClient('toolbox', 'GET', `toolbox/users/${id}`))
          .data.user
        Object.assign(this.users, { [id]: user })
      } catch (err) {
        console.log('fetchUser', err)
        /* ignore error */
      }
    },

    async fetchUsers() {
      try {
        const users = (await restClient('toolbox', 'GET', `toolbox/users`)).data
          .users
        // console.log('USERS', users)
        users.forEach(user => Object.assign(this.users, { [user.id]: user }))
      } catch (err) {
        console.error('fetchUsers', err)
        /* ignore error */
      }
    },
  },
})
