import { useAppStore } from 'mc-gcommon/stores/app.js'
import { useFlashStore } from 'mc-gcommon/stores/flash.js'
import { useLangStore } from 'mc-gcommon/stores/lang.js'
import { useUsersStore } from './users.js'
import { useStoreUpdateQueue } from 'mc-gcommon/utils/store-update-queue-hooks.js'
import { useAstorage } from 'mc-gcommon/utils/astorage-hook.js'
import { restClient } from 'mc-gcommon/utils/api-client.js'
import { idbToolbox } from '@/utils/idb-toolbox.js'
import { env } from 'mc-gcommon/utils/env.js'

const CONVERSATION_TYPES = ['support', 'complaint']
const CONVERSATION_STATES = ['new', 'open', 'rejected', 'resolved']

const DEFAULT_STATE = {
  // Last conversations update timestamp
  lastUpdatedAt: 0,
  // List of conversations
  _conversations: [],
  // Filter
  filter: {
    state: ['new', 'open'],
    type: [...CONVERSATION_TYPES],
    fulltext: '',
    user: '',
  },
  // Other stores used by this one
  _appStore: null,
  _flashStore: null,
  _usersStore: null,
  _langStore: null,
  _authStore: null,
}

export const updateQueue = useStoreUpdateQueue()

export default {
  state: DEFAULT_STATE,

  getters: {
    // Warning these getters can modify state as a side effect
    appStore: state => {
      if (!state._appStore) state._appStore = useAppStore()
      return state._appStore
    },
    flashStore: state => {
      if (!state._flashStore) state._flashStore = useFlashStore()
      return state._flashStore
    },
    usersStore: state => {
      if (!state._usersStore) state._usersStore = useUsersStore()
      return state._usersStore
    },
    langStore: state => {
      if (!state._langStore) state._langStore = useLangStore()
      return state._langStore
    },
    authStore: state => {
      if (!state._authStore) state._authStore = useLangStore()
      return state._authStore
    },
    // Get updating status
    isContentUpdating: state => updateQueue.isProcessing.value,
    // Conversation states
    states: () => CONVERSATION_STATES,
    // Conversation types
    types: () => CONVERSATION_TYPES,
    // Get conversation by id
    getConversation: state => id => state.conversations.find(i => i.id === id),
  },

  actions: {
    // Do we have notification permissions?
    getNotificationPermissions() {
      if (!('Notification' in window)) return false
      else if (Notification.permission === 'granted') return true
      else if (Notification.permission === 'denied') return false
      else return null
    },

    askForNotificationPermissions() {
      if (!('Notification' in window)) return
      Notification.requestPermission(async function (result) {
        console.log('@@ NOTIFICATION PERMISSIONS REQUEST RESULT', result)
        if (result === 'granted' && 'serviceWorker' in navigator) {
          try {
            const swReg = await navigator.serviceWorker.ready
            let subscription = await swReg.pushManager.getSubscription()
            if (!subscription) {
              const publicVapidKey = urlBase64ToUint8Array(
                env.APP_WEBPUSH_PUBLIC_KEY
              )
              subscription = await swReg.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: publicVapidKey,
              })
            }
            console.log('@@ SUBSCRIPTION', subscription)
            await registerPushNotifications(subscription)
          } catch (err) {
            console.error('Create subscription error', err)
          }
        }
      })
    },

    /**
     * Update store data from the DB
     * @param  {Array|null}  what    Updates PIDs in array or all (if what is null - not an Array)
     * @param  {Boolean} rewrite     If true, rewrite current data, else update only modified
     * @param  {Object} statePatch   Use patched state for query
     */
    update(what = null, rewrite = false, statePatch = {}) {
      console.log('UPDATE THIS', this, this.$state)
      updateQueue.enqueue(this.updateConversations, what, rewrite, statePatch)
    },

    async createConversation(post) {
      const result = await createConversation(post, { restClient })
      if (Array.isArray(result)) {
        // error
        result.forEach(args => this.flashStore.addRestClientError(...args))
        return false
      } else {
        this._conversations.unshift(result)
        return true
      }
    },

    async postMessage(conversationId, post) {
      const result = await postMessage(conversationId, post, { restClient })

      if (Array.isArray(result)) {
        // error
        result.forEach(args => this.flashStore.addRestClientError(...args))
        return false
      } else {
        const conversation = this._conversations.find(
          c => c.id === conversationId
        )
        Object.assign(conversation, result)
        return true
      }
    },

    filterToggleType(type) {
      if (CONVERSATION_TYPES.includes(type))
        toggleArrayValue(this.filter.type, type)
    },

    filterToggleState(state) {
      if (CONVERSATION_STATES.includes(state))
        toggleArrayValue(this.filter.state, state)
    },
  },
}

function toggleArrayValue(array, value) {
  const idx = array.indexOf(value)
  if (idx < 0) array.push(value)
  else array.splice(idx, 1)
}

export function arrayGetLastUpdated(arr, currentLastUpdated = 0) {
  if (!Array.isArray(arr)) return 0
  const ret = arr.reduce((acc, item) => {
    if ('updatedAt' in item) {
      const updatedAt = new Date(item.updatedAt).getTime()
      if (updatedAt > acc) acc = updatedAt
    }
    return acc
  }, currentLastUpdated)
  return ret
}

// function readFileAsync(file) {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader()

//     reader.onload = () => resolve(reader.result)
//     reader.onerror = reject
//     reader.readAsArrayBuffer(file)
//   })
// }

/**
 * Create conversation
 * @param  {Object} post    [description]
 * @param  {Object} options {
 *                            restClient:function - client api function (override default if needed)
 *                          }
 * @return {[type]}         [description]
 */
export async function createConversation(post, options = null) {
  if (!options || typeof options !== 'object') options = {}
  post = { ...post }
  post.attachments = await storeAttachments(post.attachments, options)
  const someFailed = post.attachments.some(a => typeof a !== 'string')

  if (someFailed) {
    deleteAttachments(post.attachments.filter(a => typeof a === 'string'))
    return post.attachments.filter(a => typeof a !== 'string')
  }

  try {
    const created = (
      await (options.restClient || restClient)(
        'toolbox',
        'POST',
        'toolbox/conversations/user?populate=messages,user.name,assignee.name',
        post
      )
    ).data.conversation
    // console.log('POST REPLY', created)
    return created
  } catch (err) {
    console.error('createConversation', err)
    deleteAttachments(post.attachments)
    return [[err]]
  }
}

/**
 * Post conversation message
 * @param  {String} conversationId  [description]
 * @param  {Object} post            [description]
 * @param  {Object} options         {
 *                                    restClient:function - client api function (override default if needed)
 *                                  }
 * @return {[type]}                 [description]
 */
export async function postMessage(conversationId, post, options = null) {
  if (!options || typeof options !== 'object') options = {}
  post = { ...post }
  post.attachments = await storeAttachments(post.attachments, options)
  const someFailed = post.attachments.some(a => typeof a !== 'string')

  if (someFailed) {
    deleteAttachments(post.attachments.filter(a => typeof a === 'string'))
    return post.attachments.filter(a => typeof a !== 'string')
  }

  try {
    const updated = (
      await (options.restClient || restClient)(
        'toolbox',
        'POST',
        `toolbox/conversations/user/${conversationId}?populate=messages,user.name,assignee.name`,
        post
      )
    ).data.conversation
    return updated
  } catch (err) {
    console.error('postMessage', err)
    deleteAttachments(post.attachments)
    return [[err]]
  }
}

export async function storeAttachments(attachments, options) {
  if (!Array.isArray(attachments) || attachments.length === 0) return []
  const astorage = useAstorage(null, options)
  // console.log('STORE ATTACHMENTS', astorage.constructor.name)

  let imageIndex = 0
  const requests = attachments.map(attachment => {
    if (attachment.image) {
      imageIndex++
      return astorage.storeDataAsset(
        'conversations',
        attachment.image,
        `Photo ${imageIndex}.jpeg`
      )
    } else if (attachment.file) {
      return astorage.storeFileAsset('conversations', attachment.file)
    } else return null
  })

  let results = await Promise.allSettled(requests)

  imageIndex = 0
  results = attachments.map((attachment, idx) => {
    const result = results[idx]
    if (attachment.image) {
      imageIndex++
      if (result.status === 'fulfilled') return result.value
      else {
        console.error('storeAttachments', attachment, result.reason)
        return [
          err,
          {
            pretext: 'api.attachment.failed_image',
            pretextArgs: { number: imageIndex },
          },
        ]
      }
    } else if (attachment.file) {
      if (result.status === 'fulfilled') return result.value
      else {
        console.error('storeAttachments', attachment, result.reason)
        return [
          err,
          {
            pretext: 'api.attachment.failed_file',
            pretextArgs: { name: attachment.file.name },
          },
        ]
      }
    } else return null
  })

  return results
}

export async function deleteAttachments(urls, options) {
  if (!Array.isArray(urls) || urls.length === 0) return
  const astorage = useAstorage(null, options)
  const results = await Promise.allSettled(
    urls.map(url => astorage.deleteAssetByUrl(url))
  )
  results.forEach(
    (result, idx) =>
      result.status === 'rejected' &&
      console.error('deleteAttachments', urls[idx], result.reason)
  )
}

function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/')
  const rawData = window.atob(base64)
  var outputArray = new Uint8Array(rawData.length)
  for (let i = 0; i < rawData.length; i++)
    outputArray[i] = rawData.charCodeAt(i)
  return outputArray
}

async function registerPushNotifications(subscription) {
  try {
    await restClient(
      'toolbox',
      'POST',
      'toolbox/users/register-push-notifications',
      { subscription }
    )
    return true
  } catch (err) {
    console.error('registerPushNotifications', err)
  }
  return false
}
