export default [
  {
    path: '',
    name: 'support',
    component: () => import('@/pages/support/support-index-page.vue'),
  },
  {
    path: 'conversations',
    name: 'support-conversations',
    component: () =>
      import('@/pages/support/conversations/conversations-page.vue'),
  },
  {
    path: 'conversation/:id',
    name: 'support-conversation-detail',
    component: () =>
      import('@/pages/support/conversations/conversation-detail-page.vue'),
    meta: {
      authRequired: true,
      // permissions: 'toolbox:conversation:u',
      noScroll: true,
    },
  },
  {
    path: 'wiring',
    name: 'support-wiring',
    component: () => import('@/pages/support/wiring-page.vue'),
  },
]
