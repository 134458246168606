import DBG, { Debug } from 'mc-gcommon/utils/debug.js'
import { env, envInit, envGetMcUrl } from 'mc-gcommon/utils/env.js'
envInit(import.meta.env)
DBG.setLogLevel(env.DEV ? Debug.levelAll : Debug.levelError)
import { Workbox, messageSW } from 'workbox-window'
import { createApp } from 'vue'
import { createPinia } from 'mc-gcommon/utils/pinia.js'
import { useClientEvent } from 'mc-gcommon/utils/client-event-hook.js'
import { useLangStore } from 'mc-gcommon/stores/lang.js'
import { useAuthStore } from 'mc-gcommon/stores/auth.js'
import { useAppStore } from 'mc-gcommon/stores/app.js'
import { useResizeStore } from 'mc-gcommon/stores/resize.js'
import { useSWStore } from 'mc-gcommon/stores/sw.js'
import { checkService } from 'mc-gcommon/stores/auth-common.js'
import { useSettingsStore } from '@/stores/settings.js'
import { useProductsStore } from '@/stores/products.js'
import { localesDefinition } from '@/lang/locales.js'
import App from './App.vue'
import router from './router'

const app = createApp(App)
const pinia = createPinia()
app.use(pinia)

const swStore = useSWStore()
const updateSW = registerSW({
	onNeedRefresh() {
		console.log('@@@@@@@@@ onNeedRefresh')
		swStore.updateRequested(updateSW, true)
	},
	onRegisteredSW(scriptUrl, swReg) {
		console.log('@@@@@@@@@ onRegisteredSW', scriptUrl, swReg)
		swStore.readyForOffline()
	},
	onRegistered(swReg) {
		console.log('@@@@@@@@@ onRegistered', swReg)
	},
	onRegisterError(err) {
		console.log('@@@@@@@@@ onRegisterError', err)
	},
})

const clientEvent = useClientEvent()
app.config.errorHandler = (err, vm, info) => {
	console.error('ERROR', err, info)
	try {
		clientEvent.error(err, {
			route: true,
			stores: true,
			customData: { errorInfo: info },
		})
	} catch (err) {
		console.error('VUE ERROR HANDLER CATCH', err)
	}
}

const appStore = useAppStore()
appStore.setBase(import.meta.env.APP_BASE)
useLangStore()
	.init(app, localesDefinition, 'en')
	.then(() =>
		useAuthStore().init(async () => {
			if (!appStore.isOnline) return false
			const authOnline = await checkService('app', 'auth')
			console.log('EXPIRED REFRESH TOKEN, CHECK SERVICE auth:app', authOnline)
			return authOnline
		})
	)
	.then(() => useSettingsStore().init())
	.then(() => useProductsStore().init())
	.then(() => {
		const authStore = useAuthStore()
		console.log('AUTHSTORE state', JSON.parse(JSON.stringify(authStore.$state)))
		useResizeStore().init()
		app.use(router)
		app.mount('#app')
		registerSW()
	})

function registerSW(options = {}) {
	const {
		immediate = false,
		onNeedRefresh,
		onOfflineReady,
		onRegistered,
		onRegisterError,
	} = options

	let wb
	let registration

	const updateServiceWorker = async (reloadPage = true) => {
		// console.log('@@@ UPDATE SW', reloadPage)
		if (reloadPage) {
			wb.addEventListener('controlling', event => {
				if (event.isUpdate) window.location.reload()
			})
		}

		// console.log(
		// 	' - registration',
		// 	registration,
		// 	registration && registration.waiting,
		// )
		if (registration && registration.waiting) {
			// Send a message to the waiting service worker,
			// instructing it to activate.
			// Note: for this to work, you have to add a message
			// listener in your service worker.
			await messageSW(registration.waiting, { type: 'SKIP_WAITING' })
		}
	}

	if ('serviceWorker' in navigator) {
		wb = new Workbox(
			import.meta.env.MODE === 'production'
				? import.meta.env.APP_BASE + 'sw.js'
				: '/dev-sw.js?dev-sw',
			{
				type: import.meta.env.MODE === 'production' ? 'classic' : 'module',
				scope: '/toolbox/',
			}
		)

		// Add an event listener to detect when the registered
		// service worker has installed but is waiting to activate.
		if (onNeedRefresh) {
			wb.addEventListener('waiting', onNeedRefresh)
			wb.addEventListener('externalwaiting', onNeedRefresh)
		}

		// register the service worker
		wb.register({ immediate })
			.then(r => {
				registration = r
				if (onRegistered) onRegistered(r)
			})
			.catch(e => {
				if (onRegisterError) onRegisterError(e)
			})

		navigator.serviceWorker.ready
			.then(registration => {
				// console.log('PSYNC REGISTRATION', registration)
				if ('periodicSync' in registration) {
					navigator.permissions
						.query({ name: 'periodic-background-sync' })
						.then(status => {
							// console.log('PSYNC XXX D', status)
							if (status.state === 'granted') {
								registration.periodicSync.register('products-sync', {
									// minInterval: 24 * 60 * 60 * 1000
									minInterval: 60 * 1000,
								})
								return registration.periodicSync.getTags()
							}
							return null //registration.periodicSync.getTags()
						})
						.then(tags => {
							// console.log('PSYNC XXX E', tags)
						})
				}
			})
			.catch(error => {
				console.log('PSYNC XXX SW Registration', error)
			})
	}

	return updateServiceWorker
}

function _registerSW() {
	if ('serviceWorker' in navigator) {
		navigator.serviceWorker.register(
			import.meta.env.MODE === 'production'
				? import.meta.env.APP_BASE + 'sw.js'
				: '/dev-sw.js?dev-sw',
			{
				type: import.meta.env.MODE === 'production' ? 'classic' : 'module',
				scope: '/toolbox/',
			}
		)

		navigator.serviceWorker.ready
			.then(registration => {
				// console.log('PSYNC REGISTRATION0, registration')
				if ('periodicSync' in registration) {
					navigator.permissions
						.query({ name: 'periodic-background-sync' })
						.then(status => {
							// console.log('XXX D', status)
							if (status.state === 'granted') {
								registration.periodicSync.register('products-sync', {
									// minInterval: 24 * 60 * 60 * 1000
									minInterval: 60 * 1000,
								})
								return registration.periodicSync.getTags()
							}
							return null //registration.periodicSync.getTags()
						})
						.then(tags => {
							// console.log('XXX E', tags)
						})
				}
			})
			.catch(error => {
				console.log('XXX SW Registration', error)
			})
	}
}
