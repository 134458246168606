<template>
  <SideBarMenu :items="menuItems">
    <template v-slot:trigger="{ right, bottom }">
      <Logo
        class="fixed z-50"
        :class="[
          right ? 'right-0 mr-4' : 'left-0',
          bottom ? 'bottom-0 mb-4' : 'top-0',
        ]"
      />
    </template>
    <template #header>
      <LogoText class="mx-2" />
    </template>
  </SideBarMenu>
</template>

<script>
export default {
  name: 'LayoutMenu',
}
</script>

<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { envGetMcUrl } from 'mc-gcommon/utils/env.js'
import { useAuthStore } from 'mc-gcommon/stores/auth.js'
import { useAppStore } from 'mc-gcommon/stores/app.js'
import { useLangStore } from 'mc-gcommon/stores/lang.js'
import SideBarMenu from 'mc-gcommon/components/side-bar/side-bar-menu.vue'
import SideMenuAvatar from 'mc-gcommon/components/side-bar/side-menu-avatar.vue'
import Logo from '@/assets/minib-toolbox-logo.svg?component'
import LogoText from '@/assets/minib-full-logo.svg?component'
import {
  mdiLogin,
  mdiLogout,
  mdiAccountPlusOutline,
  mdiFolderStarMultipleOutline,
  mdiCalculatorVariantOutline,
  mdiLifebuoy,
  mdiCogOutline,
  mdiProgressWrench,
  mdiHelpCircleOutline,
  mdiHomeOutline,
  mdiAccountConvertOutline,
  mdiBug,
  mdiGlassFragile,
  mdiChip,
} from '@mdi/js'

const route = useRoute()
const authStore = useAuthStore()
const appStore = useAppStore()
const langStore = useLangStore()

const menuItems = computed(() => {
  const menuTemplate = [
    {
      component: SideMenuAvatar,
      props: {
        class: 'mb-2',
      },
      to: `${envGetMcUrl('app', 'auth')}/${langStore.locale}/profile?from=${
        window.location.href
      }`,
      show: ({ authStore }) => authStore.isAuthenticated,
    },
    {
      title: 'action.signin',
      translate: true,
      action: () => authStore.authSignIn(/* route.fullPath */),
      icon: mdiLogin,
      show: ({ authStore }) => !authStore.isAuthenticated,
    },
    {
      title: 'action.signup',
      translate: true,
      action: () => authStore.authSignUp(/* route.fullPath */),
      icon: mdiAccountPlusOutline,
      show: ({ authStore }) => !authStore.isAuthenticated,
    },
    {
      auth: true,
      title: 'action.signout',
      translate: true,
      action: () => authStore.authSignOut(/* route.fullPath */),
      icon: mdiLogout,
      show: ({ authStore }) => authStore.isAuthenticated,
    },
    {
      header: true,
      title: 'app.menu.header.admin_tools',
      translate: true,
      show: ({ authStore }) => authStore.isAuthenticated,
    },
    {
      to: { name: 'bugs' },
      title: 'page.admin.bugs.title',
      translate: true,
      icon: mdiBug,
      show({ authStore }) {
        return authStore.isGrantedForRoute(this.to)
      },
    },
    {
      to: { name: 'admin-conversations' },
      title: 'page.admin.conversations.title',
      translate: true,
      icon: mdiAccountConvertOutline,
      show({ authStore }) {
        return authStore.isGrantedForRoute(this.to)
      },
    },
    {
      header: true,
      title: 'app.menu.header.user_tools',
      translate: true,
      show: true,
      // show: ({ authStore }) => authStore.isAuthenticated,
    },
    {
      to: { name: 'products' },
      title: 'page.products.title',
      translate: true,
      icon: mdiFolderStarMultipleOutline,
      // show: ({ authStore }) => authStore.isAuthenticated,
      // show({ authStore }) {
      //   return authStore.isGrantedForRoute(this.to)
      // },
    },
    {
      to: { name: 'calculators' },
      title: 'page.calculators.title',
      translate: true,
      icon: mdiCalculatorVariantOutline,
      disabled: ({ authStore, appStore }) =>
        !authStore.isAuthenticated && appStore.isOnline,
      // show: ({ authStore, appStore }) =>
      //   authStore.isAuthenticated && appStore.isOnline,
    },
    // {
    //   header: true,
    //   title: 'app.menu.header.support', translate: true,
    //   show: ({ authStore }) => authStore.isAuthenticated
    // },
    {
      to: { name: 'support' },
      title: 'page.support.title',
      translate: true,
      icon: mdiLifebuoy,
      disabled: ({ authStore }) => !authStore.isAuthenticated,
      // show: ({ authStore }) => authStore.isAuthenticated,
    },
    {
      header: true,
      title: 'app.menu.header.service_tools',
      translate: true,
    },
    {
      to: { name: 'service-controller' },
      title: 'page.service_controller.title',
      translate: true,
      icon: mdiProgressWrench,
      show({ authStore }) {
        return authStore.isGrantedForRoute(this.to)
      },
    },
    {
      to: { name: 'service-ccupload' },
      title: 'page.service_ccupload.title',
      translate: true,
      icon: mdiChip,
      show({ authStore }) {
        return authStore.isGrantedForRoute(this.to)
      },
    },
    {
      to: { name: 'service-spare-parts' },
      title: 'page.service_spare_parts.title',
      translate: true,
      icon: mdiGlassFragile,
      show({ authStore }) {
        return authStore.isGrantedForRoute(this.to)
      },
    },
    {
      separator: true,
      title: '',
    },
    {
      to: { name: 'home' },
      title: 'page.home.title',
      translate: true,
      icon: mdiHomeOutline,
    },
    {
      to: { name: 'settings' },
      title: 'page.settings.title',
      translate: true,
      icon: mdiCogOutline,
    },
    {
      to: { name: 'help' },
      title: 'page.help.title',
      translate: true,
      icon: mdiHelpCircleOutline,
    },
    {
      header: true,
      title: 'VERSION ' + appStore.version,
    },
  ]

  const ret = menuTemplate
    .filter(item => {
      // console.log(
      //   '>>>>>>',
      //   'isA',
      //   authStore.isAuthenticated,
      //   'shEx',
      //   item.title,
      //   !item.show,
      //   'sh',
      //   item.show && item.show({ authStore })
      // )
      // console.log(
      //   'ITEM IS GRANTED',
      //   item,
      //   item.show,
      //   item.show && item.show({ authStore }),
      //   authStore
      // )
      if (!('show' in item)) return true
      if (typeof item.show === 'function')
        return item.show({ authStore, appStore })
      return item.show
    })
    .map(item => {
      const ret = { ...item }
      delete ret.show
      if (ret.disabled && typeof ret.disabled === 'function')
        ret.disabled = ret.disabled({ authStore, appStore })
      // if (ret.title) ret.title = langStore.t(ret.title)
      if (ret.to && typeof ret.to === 'object') {
        if (!ret.to.params) ret.to.params = {}
        if (!ret.to.params.locale) ret.to.params.locale = langStore.locale
      }
      return ret
    })

  if (ret.length) {
    let idx = 0
    while (idx < ret.length - 1) {
      if (ret[idx].header && (ret[idx + 1].header || ret[idx + 1].separator))
        ret.splice(idx, 1)
      else idx++
    }
  }

  return ret
})
</script>
