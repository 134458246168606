import { localesMerge } from 'mc-gcommon/lang/locales-merge.js'

const pluralRule = (choice, choicesLength, orgRule) => {
	if (choice === 0) return 0

	const teen = choice > 10 && choice < 20
	const endsWithOne = choice % 10 === 1

	if (!teen && endsWithOne) return 1
	if (!teen && choice % 10 >= 2 && choice % 10 <= 4) return 2
	return choicesLength < 4 ? 2 : 3
}

export default {
	code: 'cs',
	pluralRule,
	async load() {
		try {
			const toolboxMessages = await import('./messages/cs.js')
			const mcgcommonMessages = await import('mc-gcommon/lang/messages/cs.js')
			const messages = localesMerge(
				mcgcommonMessages?.default || {},
				toolboxMessages?.default || {}
			)
			return messages
		} catch (err) {
			console.error("Failed to load locale 'cs'", err)
			return {}
		}
	},
}
