<template>
	<main
		class="
			card
			max-w-full
			md:max-w-lg
			bg-error
			text-error-content
			mx-auto
			sm:mt-8
			shadow-md
			border-error-content border border-4
		"
	>
		<div class="card-body items-center">
			<h2 class="card-title uppercase" v-t="'error.title'" />
			<h1 class="card-title text-6xl my-4">{{ code }}</h1>
			<p class="text-center mb-4" v-t="codeText" />
			<h2 v-if="backRoute">
				{{ $t('error.back_to') }}
				<AppLink :to="backRoute" label="error.safety" />
			</h2>
			<ul v-else>
				<li v-for="app in apps" :key="app.path">
					<AppLink :to="app.path" hover>
						<AppImg
							:src="app.icon"
							class="
								inline-block
								w-10
								md:w-16
								h-10
								md:h-16
								bg-neutral
								shadow-lg
								rounded-xl
								p-1
								m-1
								mr-4
							"
						/>
						<span class="text-lg font-semibold" v-t="app.name" />
					</AppLink>
				</li>
			</ul>

			<div v-if="props.error" class="divider my-8" />
			<p v-if="props.error" class="self-start" v-t="'error.' + props.error" />
		</div>
	</main>
</template>

<script>
	import AppImg from 'mc-gcommon/components/app/app-img.vue'

	export default {
		name: 'Page4xx'
	}

	const _apps = [
		{
			name: 'MINIB Website',
			path: 'https://www.minib.cz',
			icon: 'minib-logo.svg'
		},
		{
			name: 'MINIB Toolbox',
			path: '/toolbox/',
			icon: 'minib-toolbox-logo.svg'
		},
		{
			name: 'MINIB Factory',
			path: '/factory/',
			icon: 'minib-factory-logo.svg'
		}
	]
</script>

<script setup>
	import { computed } from 'vue'
	import { useRoute } from 'vue-router'
	import { useAppStore } from 'mc-gcommon/stores/app.js'
	import AppLink from 'mc-gcommon/components/app/app-link.vue'

	const props = defineProps({
		code: {
			type: [Number, String]
		},
		error: {
			type: String
		}
	})

	const route = useRoute()
	const appStore = useAppStore()

	const mmbBaseUrl = import.meta.env.APP_MMB_BASE_URL

	const apps = computed(() =>
		_apps.map(app => ({
			...app,
			path: mmbBaseUrl + app.path,
			icon: appStore.appUrl('images/' + app.icon)
		}))
	)

	const code = computed(() => props.code || 400)
	const codeText = computed(() => 'error.code.' + (props.code || 'generic'))

	const backRoute = computed(() =>
		route.query && route.query.from
			? route.query.from.match(/^.+?[^\/:](?=[?\/]|$)/)[0]
			: null
	)

	// console.log('BACK', backRoute.value)
</script>
