import { localesMerge } from 'mc-gcommon/lang/locales-merge.js'

export default {
	code: 'en',
	async load() {
		try {
			const toolboxMessages = await import('./messages/en.js')
			const mcgcommonMessages = await import('mc-gcommon/lang/messages/en.js')
			const messages = localesMerge(
				mcgcommonMessages?.default || {},
				toolboxMessages?.default || {}
			)
			return messages
		} catch (err) {
			console.error("Failed to load locale 'en'", err)
			return {}
		}
	},
}
