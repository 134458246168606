import { IdbBase } from 'mc-gcommon/utils/idb-base.js'
import dbg from 'mc-gcommon/utils/debug.js'

/**
 * IDB Schema version and options
 * Version 12:
 * - settings (index none)
 * - - calculators_params: {
 * 				heatingGradient:number, coolingGradient:number, fanSpeed:string
 * 		 }
 * - products (index 'PID') - products with evaluated variants {
 * 			id:string, PID:strng, class:string,	name:object, description:object,
 * 			props:object, updatedAt:string,
 * 			variants:object {
 * 				<w>_<l>_<v>: {
 * 					width:number, height:number, variant:string,
 * 					_matrix:object {
 * 						heating|cooling: {
 * 							<gradient>:array of powers for each std. length [<off>,<low>,<medium>,<high>]
 * 						}
 * 					}
 * 				}
 * 			}
 * - product_classes (index 'name') - product classes {
 *			id:string, name:string, props:object, updatedAt:string
 * 	 }
 * - conversations (index 'id') - user's conversations {
 *			<id>: {
 *				id:string, user:string, type:string, subject:string,
 *				state:string, assignee:string (assignee ID), updatedAt:string,
 *				messages:array {
 *					text:string, postedBy:string, postedByName:string, postedAt:string,
 *					attachments:array (url)
 *				},
 *				history:array {
 *					state:string, timestamp:string
 *				}
 *			}
 * 	 }
 *
 * Version 13:
 * ADDED
 * - controller_presets (index 'id') - user's controller presets {
 *			<id>: { id:string, name:string, controllerId:string, conf:object }
 * Version 14:
 * ADDED
 * - conversation-sync (index 'timestamp') - user's conversations sync {
 *			<timestamp>: {
 *				timestamp:number,
 *				action:string - 'new', 'post', 'assign', 'state'
 *				id:string (only for 'post', 'assign', 'state')
 *				type:string (only for 'new')
 *				subject:string (only for 'new')
 *				sn:string (only for 'new' and type === 'complaint')
 *				message:string (only for 'new', 'post')
 *				attachments:array({ timestamp, file|image, mimetype}) (only for 'new', 'post')
 *				assignee:string (only for 'assign')
 *				state:string (only for 'state')
 *			}
 * 	 }
 */

const IDB_NAME = 'mc-toolbox'
const IDB_VERSION = 14
const IDB_OPTIONS = {}
const IDB_SCHEMA = {
	settings: {
		id: ['calculator_params', 'system_of_units'],
	},
	product_class: { id: 'name' },
	product: { id: 'PID' },
	conversation: { id: 'id' },
	conversation_sync: { id: 'timestamp' },
	controller_preset: { id: 'id' },
}

class IdbToolbox extends IdbBase {
	constructor() {
		super(IDB_NAME, IDB_VERSION, IDB_SCHEMA, IDB_OPTIONS)
	}

	async _upgrade(db, oldVersion, newVersion) {
		dbg.logDebug('IdbToolbox upgrade', oldVersion, newVersion)

		let uf
		if (newVersion === 13) uf = this._idbUpgradeTo13
		if (newVersion === 14) uf = this._idbUpgradeTo14
		if (!uf) uf = this._upgradeReInit

		// console.log('- DB', db)
		// console.log('- OSN', db.objectStoreNames)
		// console.log('- UF', uf)
		await uf(db, oldVersion, newVersion)
		await super._upgrade(db, oldVersion, newVersion)
	}

	async _idbUpgradeTo13(db, ov, nv) {
		console.log('- To13')
	}

	async _idbUpgradeTo14(db, ov, nv) {
		console.log('- To14')
	}
}

export const idbToolbox = new IdbToolbox()
