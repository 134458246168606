<template>
  <Layout>
    <router-view />
  </Layout>
</template>

<script>
  export default {
    name: 'App'
  }
</script>

<script setup>
  import Layout from '@/components/layout/layout.vue'
</script>

<style lang="sass">
  @import '@/assets/main.sass'
</style>
