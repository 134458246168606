import { defineStore } from 'mc-gcommon/utils/pinia'
import { useFlashStore } from 'mc-gcommon/stores/flash.js'
import {
  CALCULATORS_PARAMS_VALUES,
  useSettingsStore,
} from '@/stores/settings.js'
import { restClient } from 'mc-gcommon/utils/api-client.js'
import { useStoreUpdateQueue } from 'mc-gcommon/utils/store-update-queue-hooks.js'
import { idbToolbox } from '@/utils/idb-toolbox.js'

const STORE = 'products'

// const CONTENT_UPDATE_PERIOD = 24 * 60 * 60 * 1000

// const _objectLength = obj => Object.keys(obj).length
// const _mapObjectToArray = (obj, key) => Object.keys(obj).map(k => obj[k])
const _variantId = v => `${v.width}_${v.height}_${v.variant || ''}`
const _mapArrayToObject = (arr, key) =>
  arr.reduce((acc, v) => ((acc[v[key]] = v), acc), {})
const _filterObject = (obj, func, ...ud) => {
  return Object.keys(obj)
    .filter(k => func(obj[k], ...ud))
    .reduce((acc, k) => ((acc[k] = obj[k]), acc), {})
}
const filterObject = (obj, func, ...ud) =>
  Object.keys(obj).reduce((acc, k) => {
    const f = func(obj[k], ...ud)
    if (f === true) acc[k] = obj[k]
    else if (f !== false) acc[k] = f
    return acc
  }, {})
const updateQueue = useStoreUpdateQueue()

const DEFAULT_STATE = {
  // List of product classes
  classes: {},
  // Last classes update timestamp
  classesLastUpdatedAt: 0,
  // List of products
  products: {},
  // Last product update timestamp
  productsLastUpdatedAt: 0,

  // Product class filter (one class filter)
  filterClass: 'convector',
  // Selected filter values
  filterSelection: {},
  // Properties fitler is active
  filterActive: true,
}

export const useProductsStore = defineStore({
  id: STORE,

  state: () => JSON.parse(JSON.stringify(DEFAULT_STATE)),

  getters: {
    // Get updating status
    isContentUpdating: state => updateQueue.isProcessing.value,

    // Products display order
    productsOrder: state =>
      Object.keys(state.products).sort((pid1, pid2) => {
        const do1 = state.products[pid1].displayOrder || 0
        const do2 = state.products[pid2].displayOrder || 0
        // console.log('SORT', do1, do2, do1 > do2 ? 1 : do1 < do2 ? -1 : 0)
        return do1 > do2 ? -1 : do1 < do2 ? 1 : 0
      }),

    // Number of Products
    productsCount: state => Object.keys(state.products).length,

    // Filter definition
    filterDef: state =>
      state.filterActive && state.filterClass
        ? _getFilterDef({
            [state.filterClass]: state.classes[state.filterClass],
          })
        : {},

    // Products display order
    filteredProductsOrder: state =>
      Object.keys(state.filteredProducts).sort((pid1, pid2) => {
        const do1 = state.products[pid1].displayOrder || 0
        const do2 = state.products[pid2].displayOrder || 0
        return do1 > do2 ? 1 : do1 < do2 ? -1 : 0
      }),

    // Number of Products and variants
    filteredProductsCount: state => Object.keys(state.filteredProducts).length,
    filteredProductsVariantsCount: state =>
      Object.keys(state.filteredProducts).reduce(
        (acc, prod) =>
          acc + Object.keys(state.filteredProducts[prod].variants).length,
        0
      ),

    classFilteredProducts: state =>
      state.filterClass
        ? Object.keys(state.products)
            .filter(p => state.products[p].class === state.filterClass)
            .reduce((acc, p) => ((acc[p] = state.products[p]), acc), {})
        : state.products,

    filteredProducts: state => {
      // console.log('FILTERED PRODUCTS')
      if (!state.filterActive) return state.products
      // let products = state.filterClass
      //   ? Object.keys(state.products)
      //       .filter(p => state.products[p].class === state.filterClass)
      //       .reduce((acc, p) => ((acc[p] = state.products[p]), acc), {})
      //   : state.products
      let products = state.classFilteredProducts

      const filterValuesMap = _getFilterValuesMap(
        state.filterDef,
        state.filterSelection
      )

      // console.log('FILTER VALUES MAP', filterValuesMap)
      products = JSON.parse(JSON.stringify(products))

      const filters = Object.keys(state.filterDef)
        .map(fk => ({ fk, ...state.filterDef[fk] }))
        .sort((f1, f2) => {
          const _fn2v = fn => {
            const n2vmap = {
              'options:or': 1,
              'options:and': 2,
              range: 3,
              dimensions: 4,
            }
            return n2vmap[fn] || 100
          }
          const v1 = _fn2v(f1.filter)
          const v2 = _fn2v(f2.filter)
          return v1 > v2 ? 1 : v1 < v2 ? -1 : 0
        })
      // console.log('FILTERS', filters)

      Object.keys(products).forEach(pid => {
        const product = products[pid]
        const props = product.props

        // console.log('@@@ FILTER PRODUCT', pid)
        let keep = true
        for (const fd of filters) {
          const fv = filterValuesMap[fd.fk]
          const pv = _propValueToFilterValue(
            fd.fk,
            props[fd.fk],
            state.filterDef
          )
          // console.log('@@@ A', fd.fk, fd, pv)

          // Filter out options - remove all product if dont match
          if (fd.filter === 'options:and') keep = _filterMatchOptionsAnd(fv, pv)
          else if (fd.filter === 'options:or')
            keep = _filterMatchOptionsOr(fv, pv)
          // if (!keep) console.log('****', pid, 'removed at AND/OR', fv)
          if (!keep) break
          // console.log('@@@ B', keep)

          // Filter out range - reduce product range according to the filter
          if (fd.filter === 'range') keep = _filterReduceRange(fv, pv)
          // console.log('KEEP RANGE', keep)
          // if (!keep) console.log('****', pid, 'removed at RANGE', fv)
          if (!keep) break
          // console.log('@@@ C', keep)
          if (typeof keep === 'object') {
            props[fd.fk] = {
              ...props[fd.fk],
              discrete: keep.value,
              min: keep.minmax[0],
              max: keep.minmax[1],
            }
            keep = true
            _updateProductPowers(product, state.products[pid])
          }

          // Filter out dimensions - reduce product dimensions according to the filter
          if (fd.filter === 'dimensions')
            keep = _filterReduceDimensions(product, fd.fk, fv, pv)
          // console.log('@@@ D', keep)
          // console.log('KEEP DIMS', keep)
          // if (!keep) console.log('****', pid, 'removed at DIMENSIONS', fv)
          if (!keep) break
          if (Array.isArray(keep)) {
            const vIds = keep.map(k => k.vId)
            product.variants = Object.keys(product.variants).reduce(
              (acc, v) => (
                vIds.includes(v) && (acc[v] = product.variants[v]), acc
              ),
              {}
            )
            props[fd.fk] = { ...props[fd.fk], value: keep.map(k => k.v) }

            _updateProductPowers(product, state.products[pid])
            keep = true
          }

          // Filter out power - reduce product dimensions according to the filter
          if (
            filterValuesMap.regime.options.includes(fd.filter) &&
            product.power &&
            product.power.variants &&
            Object.keys(product.power.variants).length > 0
          )
            keep = _filterReducePower(product, fd.fk, fv, pv)
          // console.log('@@@ E', keep)
          // console.log('KEEP DIMS', keep)
          // if (!keep) console.log('****', pid, 'removed at POWER', fv)
          if (!keep) break
          if (Array.isArray(keep)) {
            const vIds = keep.map(k => k.vId)
            product.variants = Object.keys(product.variants).reduce(
              (acc, v) => (
                vIds.includes(v) && (acc[v] = product.variants[v]), acc
              ),
              {}
            )
            props.dimensions = {
              ...props.dimensions,
              value: keep.map(k => k.v),
            }
            keep = true
          }
        }
        if (!keep) {
          delete products[pid]
          return
        }
      })

      return products
    },
  },

  actions: {
    // Initialize store
    async init() {
      await this.idbLoad()
      // console.log('@@@ INIT', Object.keys(this.products))
      updateQueue.enqueue(this.update)
    },

    // Clear store data (including offline)
    async clear(offline = false) {
      // TODO wait for all tasks in queue are done, then clean
      if (offline) {
        await idbToolbox.productClassDeleteAll()
        await idbToolbox.productDeleteAll()
      }
      Object.assign(this, JSON.parse(JSON.stringify(DEFAULT_STATE)))
    },

    /**
     * Load Store data from the IDB
     */
    async idbLoad() {
      // console.log('@@@ IDB LOAD')
      try {
        const ret = await this._idbLoadProductClasses()
        this.$patch(ret)
      } catch (err) {
        console.error('_idbLoadProductClasses', err)
      }
      try {
        const ret = await this._idbLoadProducts()
        console.log('++++ IDB LOAD PRODUCTS', ret)
        Object.keys(ret.products).forEach(
          pid => (ret.products[pid].power = null)
        )
        console.log('++++ POWER = null', ret)
        this.$patch(ret)
        Object.keys(this.products).forEach(pid =>
          _updateProductPowers(this.products[pid], this.products[pid])
        )
      } catch (err) {
        console.error('_idbLoadProducts', err)
      }
      // console.log('@@@ IDB LOADED', Object.keys(this.products))
    },

    /**
     * Update store data from the DB
     * @param  {Array|null}  what    Updates PIDs in array or all (if what is null - not an Array)
     * @param  {Boolean} rewrite     If true, rewrite current data, else update only modified
     */
    async update(what = null, rewrite = false) {
      // console.log('@@@ PRODUCTS UPDATE', what, rewrite)
      updateQueue.enqueue(this.updateProductClasses, null, rewrite)
      updateQueue.enqueue(this.updateProducts, what, rewrite)
    },

    calculatorsParamsChanged() {
      Object.keys(this.products).forEach(pid =>
        updateQueue.enqueue(
          self =>
            new Promise((resolve, reject) => {
              _updateProductPowers(self.products[pid], self.products[pid])
              setTimeout(resolve, 1)
            }),
          this
        )
      )
    },

    systemOfUnitsChanged() {
      updateQueue.enqueue(this.update, null, true)
    },

    /**
     * Update store data from the DB
     * @param  {Array|null}  what    Updates PIDs in array or all (if what is null - not an Array)
     * @param  {Boolean} rewrite     If true, rewrite current data, else update only modified
     */
    async updateProductClasses(what = null, rewrite = false) {
      // console.log(
      //   'UPDATE PRODUCT CLASSES',
      //   what,
      //   rewrite,
      //   Object.keys(this.classes)
      // )
      let classes
      const select = Array.isArray(what) ? '&name=' + what.join(',') : ''
      const newer =
        rewrite || Array.isArray(what)
          ? ''
          : `&updatedAt>${this.classesLastUpdatedAt}`

      try {
        classes = (
          await restClient(
            'toolbox',
            'GET',
            'toolbox/product-classes?limit=5000&select=name,props,updatedAt' +
              '&units=' +
              useSettingsStore().systemOfUnits +
              select +
              newer
          )
        ).data.productclasses
      } catch (err) {
        console.error('updateProductClasses', err)
        return []
      }

      // console.log('CLASSES 1', classes)
      if (!Array.isArray(what) || rewrite)
        this.classesLastUpdatedAt = arrayGetLastUpdated(
          classes,
          this.classesLastUpdatedAt
        )
      const updatedNames = classes.map(c => c.name)
      classes = _mapArrayToObject(classes, 'name')
      if (rewrite) this.classes = classes
      else Object.assign(this.classes, classes)

      // update IDB with the new classes data
      this._idbSaveProductClasses(updatedNames).then(() => {
        if (!Array.isArray(what) && rewrite)
          this._idbCleanProductClasses(updatedNames)
      })

      return Object.keys(classes)
    },

    async updateProducts(what = null, rewrite = false) {
      // console.log('@@@ UPDATE PRODUCTS', what, rewrite)
      let products
      const select = Array.isArray(what) ? '&PID=' + what.join(',') : ''

      try {
        products = (
          await restClient(
            'toolbox',
            'GET',
            'toolbox/products?limit=5000&active=true&populate=class.name&select=PID,class,name,description,displayOrder,webLink,props,images,updatedAt' +
              '&units=' +
              useSettingsStore().systemOfUnits +
              select
            // + newer
          )
        ).data.products
      } catch (err) {
        console.error('updateProducts', err)
        return []
      }
      products.forEach(p => (p.power = null))

      // Remove old products
      const keepPids = []
      Object.keys(this.products).forEach(pid => {
        const pidx = products.findIndex(p => p.PID === pid)
        if (pidx < 0) {
          // console.log('@@@ REMOVE PRODUCT', pid)
          delete this.products[pid]
        } else keepPids.push(pid)
      })
      this._idbCleanProducts(keepPids)

      // console.log(
      //   '@@@ PRODUCTS LEN1',
      //   products.length,
      //   Object.keys(this.products).length
      // )
      products = products.filter(product => {
        // console.log(
        //   '@@@ FILTER PRODUCT',
        //   product.PID,
        //   !this.products[product.PID],
        //   this.products[product.PID] &&
        //     product.updatedAt !== this.products[product.PID].updatedAt
        // )
        return (
          !this.products[product.PID] ||
          product.updatedAt !== this.products[product.PID].updatedAt
        )
      })
      // console.log('@@@ PRODUCTS LEN2', products.length)
      if (!Array.isArray(what) || rewrite)
        this.productsLastUpdatedAt = arrayGetLastUpdated(
          products,
          this.productsLastUpdatedAt
        )
      const updatedPIDs = []
      products = products.forEach(product => {
        try {
          const old = this.products[product.PID]
          product.class = product.class.name
          product.variants = (product.props.dimensions.value || []).reduce(
            (acc, v) => {
              const vId = _variantId(v)
              acc[vId] = (!rewrite && old && old.variants[vId]) || {}
              Object.assign(acc[vId], v)
              return acc
            },
            {}
          )
          // console.log('@@@ ---', product.PID, product)
          this.products[product.PID] = product
          updatedPIDs.push(product.PID)
        } catch (err) {
          console.error('Invalid product', product.PID, err)
        }
      })

      // update IDB with the new products
      // console.log('@@@ SAVE PRODUCTS A', updatedPIDs)
      this._idbSaveProducts(updatedPIDs)

      updateQueue.enqueueFront(async self => {
        for (const pid in self.products) {
          const product = self.products[pid]
          // console.log(
          //   '@@@@',
          //   product.variants &&
          //     Object.keys(product.variants).some(
          //       v => !product.variants[v]._matrix
          //     )
          // )
          if (
            product.variants &&
            Object.keys(product.variants).some(
              v => !product.variants[v]._matrix
            )
          ) {
            try {
              await _updateProductMatrix(product)
              // console.log('@@@ SAVE PRODUCTS B', [product.PID])
              await self._idbSaveProducts.bind(self)([product.PID])
            } catch (err) {
              console.error('update matrix', product.PID, err)
            }
          }
        }
      }, this)

      return updatedPIDs
    },

    async calculateGetArgs(pid) {
      // console.log('@@@ CALCULATE GET ARGS', pid)
      try {
        const response = await restClient(
          'toolbox',
          'GET',
          `toolbox/products/${pid}/calculate`,
          null
        )
        // console.log('@@@ CALCULATE GET ARGS:', response)
        return response.data
      } catch (err) {
        return null
      }
    },

    async calculate(pid, args) {
      const units = useSettingsStore().systemOfUnits
      // console.log('@@@ CALCULATE', pid, args)
      const body = { units, ...args }
      const response = await restClient(
        'toolbox',
        'POST',
        `toolbox/products/${pid}/calculate`,
        body,
        { timeout: 10000 }
      )
      // console.log('CALCULATE RESPONSE', response)
      return response
    },

    async _idbLoadProducts() {
      const ret = {}
      ret.products = await idbToolbox.productGetAll()
      ret.productsLastUpdatedAt = arrayGetLastUpdated(ret.products)
      ret.products = _mapArrayToObject(ret.products, 'PID')
      return ret
    },

    async _idbLoadProductClasses() {
      const ret = {}
      ret.classes = await idbToolbox.productClassGetAll()
      ret.classesLastUpdatedAt = arrayGetLastUpdated(ret.classes)
      ret.classes = _mapArrayToObject(ret.classes, 'name')
      return ret
    },

    async _idbSaveProducts(PIDs) {
      // console.log('@@@ IDB SAVE PRODUCTS', PIDs)
      for (const pid of PIDs)
        try {
          const p = { ...this.products[pid] }
          delete p.power
          await idbToolbox.productPut(p)
        } catch (err) {
          console.error('idbSaveProducts', pid, err)
        }
    },

    async _idbSaveProductClasses(classes) {
      for (const name of classes)
        try {
          // console.log('SAVE PRODUCT CLASS', name, classes[name])
          await idbToolbox.productClassPut(this.classes[name])
        } catch (err) {
          console.error('idbSaveProductClasses', name, err)
        }
    },

    async _idbCleanProducts(keepIds) {
      // console.log('@@@ CLEAN PRODUCTS', keepIds)
      const removeIds = (await idbToolbox.productGetIds()).filter(
        id => !keepIds.includes(id)
      )
      // console.log('@@@ CLEAN PRODUCTS REMOVE', removeIds)
      for (const id of removeIds) {
        try {
          await idbToolbox.productDelete(id)
          delete this.products[id]
        } catch (err) {
          console.error('idbCleanProducts', id, err)
        }
      }
    },

    async _idbCleanProductClasses(keepIds) {
      // console.log('CLEAN PRODUCT CLASSES', keepIds)
      const removeIds = (await idbToolbox.productClassGetIds()).filter(
        id => !keepIds.includes(id)
      )
      // console.log('REMOVE PRODUCT CLASSES rIds', removeIds)
      for (const id of removeIds) {
        try {
          await idbToolbox.productClassDelete(id)
          delete this.classes[id]
        } catch (err) {
          console.error('idbCleanProductClasses', id, err)
        }
      }
    },
  },
})

function arrayGetLastUpdated(arr, currentLastUpdated = 0) {
  if (!Array.isArray(arr)) return 0
  const ret = arr.reduce((acc, item) => {
    if ('updatedAt' in item) {
      const updatedAt = new Date(item.updatedAt).getTime()
      if (updatedAt > acc) acc = updatedAt
    }
    return acc
  }, currentLastUpdated)
  return ret
}

const PROP_HEATING = {
  base: 'integer',
  filter: 'heating',
  label: 'heating',
  minmax: [0, 100000],
}
const PROP_COOLING = {
  base: 'integer',
  filter: 'cooling',
  label: 'cooling',
  minmax: [0, 100000],
}

// Compute filter definition from classes object
function _getFilterDef(classes) {
  const def = {}

  // console.log(
  //   'GETFILTERDEF------------------------------------------------',
  //   classes
  // )

  Object.keys(classes).forEach(clsName => {
    // console.log('--- CLASS', clsName)
    const cls = classes[clsName]
    const props = cls.props
    const propsNames = (props && Object.keys(props)) || []
    if (propsNames.length === 0) return {}

    propsNames.forEach(propName => {
      const prop = props[propName]
      if (!prop.meta || !prop.meta.filter) return
      const d = _propDefinitionToFilterDef(propName, prop)
      // console.log('DEF', propName, d, def)
      if (d) def[propName] = d
    })
  })

  def.heating = PROP_HEATING
  def.cooling = PROP_COOLING

  // console.log('GETFILTERDEF', def)
  return def
}

function _propDefinitionToFilterDef(propName, prop) {
  if (propName === 'heating') prop = PROP_HEATING
  else if (propName === 'cooling') prop = PROP_COOLING
  // console.log('PROPDEF TO FILTERDEF', propName, prop)
  const ret = {
    base: prop.base,
    label: 'props.filter.' + propName + '.name',
  }

  switch (prop.base) {
    case 'boolean':
      ret.filter = 'options:or'
      ret.options = [propName, 'no' + propName]
      break
    case 'string':
    case 'string[]':
      ret.filter = 'options:' + ((prop.meta && prop.meta.filter) || 'or')
      if (prop.discrete) ret.options = [...prop.discrete]
      if (prop.regexp) ret.regexp = new RegExp(prop.regexp)
      break
    case 'integer':
      ret.filter = (prop.meta && prop.meta.filter) || 'range'
      if (prop.discrete) ret.options = [...prop.discrete]
      if (!isNaN(prop.min) && !isNaN(prop.max))
        ret.minmax = [prop.min, prop.max]
      break
    case 'object[]':
      if (!(prop.meta && prop.meta.filter)) {
        // console.log('- ret 1', null, prop.meta && prop.meta.filter)
        return null
      }
      ret.filter = prop.meta.filter
      ret.keys = prop.keys
      break
    default:
      console.error('Unknown prop base', prop.base)
      // console.log('- ret 2', null, prop.base)
      return null
  }

  if (ret.options)
    ret.optionsLabel = ret.options.map(
      o => 'props.filter.' + propName + '.value.' + o
    )

  // console.log('- ret 3', ret, ret.filter)
  return ret
}

function _propValueToFilterValue(propName, prop, filterDef) {
  if (propName === 'heating') prop = PROP_HEATING
  else if (propName === 'cooling') prop = PROP_COOLING
  // console.log('PVTFV', propName, prop, filterDef[propName])
  // console.log('PVTFV', propName, prop.base, filterDef)
  // const ret = _propDefinitionToFilterDef(propName, prop)
  // const ret = { ...filterDef[propName] }
  const ret =
    prop &&
    Object.assign(
      { ...filterDef[propName] },
      _propDefinitionToFilterDef(propName, prop)
    )
  // console.log('- ret', ret)
  if (ret) {
    delete ret.base
    delete ret.filter
    delete ret.label
    delete ret.optionsLabel
    if ('value' in prop) {
      // console.log('- value', prop.value, ret)
      switch (prop.base) {
        case 'boolean':
          ret.value = [(prop.value ? '' : 'no') + propName]
          break
        case 'string':
        case 'integer':
          ret.value = [prop.value]
          break
        case 'string[]':
          ret.value = [...prop.value]
          break
        case 'object[]':
          // console.log('OBJECT[]', propName, prop)
          ret.value = prop.value.map(v => ({ ...v }))
          break
        default:
          console.error('Unknown prop base', prop.base)
          break
      }
    }
  }

  // console.log('- ret 3', propName, ret, ret.filter)
  return ret
}

function _getFilterValuesMap(filterDef, filterSelection) {
  return Object.keys(filterDef).reduce((acc, prop) => {
    acc[prop] = { ...filterSelection[prop] }
    const fd = filterDef[prop]
    if (fd.options) {
      const fso = filterSelection[prop] && filterSelection[prop].options
      if (!fso) acc[prop].options = fd.options
      else acc[prop].options = fd.options.filter((_, idx) => fso[idx])
    }
    if (fd.regexp) acc[prop].regexp = fd.regexp
    return acc
  }, {})
}

function _filterMatchOptionsAnd(filterValue, propValue) {
  if (filterValue.options.length === 0) return true
  return !!(
    (propValue.value &&
      filterValue.options.every(v => propValue.value.includes(v))) ||
    (filterValue.regexp &&
      propValue.value.every(v => filterValue.regexp.test(v)))
  )
}

function _filterMatchOptionsOr(filterValue, propValue) {
  if (filterValue.options.length === 0) return true
  return !!(
    (propValue &&
      propValue.value.some(
        v => filterValue.options && filterValue.options.includes(v)
      )) ||
    (filterValue.regexp && filterValue.regexp.test(v))
  )
}

function _filterReduceRange(filterValue, propValue) {
  // console.log('@@@RR', filterValue, propValue)
  if (!filterValue.range) return true
  let value
  let minMax
  if (propValue.options) {
    // Filtered discrete values
    value = propValue.options.filter(
      v => filterValue.range[0] <= v && filterValue.range[1] >= v
    )
    if (value.length === propValue.options.length) value = true
    else if (value.length === 0) value = false
    // console.log('@@@RR', 'VALUE', value)
  }
  // console.log('@@@RR', 'PROP MINMAX', propValue.minmax, propValue)
  if (propValue.minmax) {
    // Filtered range
    if (
      propValue.minmax[1] < filterValue.range[0] ||
      propValue.minmax[0] > filterValue.range[1]
    ) {
      minMax = false
    } else if (
      propValue.minmax[1] <= filterValue.range[1] &&
      propValue.minmax[0] >= filterValue.range[0]
    ) {
      minMax = true
    } else {
      minMax = [
        filterValue.range[0] > propValue.minmax[0]
          ? filterValue.range[0]
          : propValue.minmax[0],
        filterValue.range[1] < propValue.minmax[1]
          ? filterValue.range[1]
          : propValue.minmax[1],
      ]
    }
    // console.log('@@@RR', 'MINMAX', minMax)
  }

  let ret = false
  if (value === true && minMax === true) ret = true
  else if (value || minMax) {
    ret = {}
    if (value === true) ret.value = propValue.options
    else if (value === undefined) ret.value = []
    else ret.value = value
    if (minMax === true) ret.minmax = propValue.minmax
    else if (minMax === undefined) ret.minmax = [0, 0]
    else ret.minmax = minMax
  }
  return ret
}

function _filterReduceDimensions(product, propName, filterValue, propValue) {
  // console.log('RD', propName, filterValue, propValue)
  if (!propValue || !propValue.value || !filterValue) return false

  let dimensions = propValue.value
  const originalLen = dimensions.length
  dimensions = dimensions.filter(value => {
    value = { variant: 'standard', ...value }
    return Object.keys(value).every(vKey => {
      // console.log('DIMENSIONS', vKey, value, filterValue)
      if (['width', 'height'].includes(vKey)) {
        return (
          !filterValue.keys ||
          !(vKey in filterValue.keys) ||
          (value[vKey] >= filterValue.keys[vKey][0] &&
            value[vKey] <= filterValue.keys[vKey][1])
        )
      } else {
        if (
          !filterValue.keys ||
          !filterValue.keys[vKey] ||
          filterValue.keys[vKey].length === 0
        )
          return true
        return ((filterValue.keys && filterValue.keys[vKey]) || []).includes(
          value[vKey]
        )
      }
    })
  })
  if (dimensions.length === 0) return false
  if (dimensions.length === originalLen) return true
  dimensions = dimensions.map(v => ({ vId: _variantId(v), v }))
  // console.log('--- ret', dimensions)
  return dimensions
}

function _filterReducePower(product, propName, filterValue, propValue) {
  // console.log('RP', propName, filterValue, propValue, product.PID)

  let dimensions = product.props.dimensions.value
  const originalLen = dimensions.length
  dimensions = dimensions.filter(value => {
    const vId = _variantId(value)
    const power =
      product.power &&
      product.power.variants &&
      product.power.variants[vId] &&
      product.power.variants[vId][propName]
    if (!power || !filterValue.range) return false
    return power[0] <= filterValue.range[1] && power[1] >= filterValue.range[0]
  })

  if (dimensions.length === 0) return false
  if (dimensions.length === originalLen) return true

  return dimensions.map(v => ({ vId: _variantId(v), v }))
}

async function _updateProductMatrix(product) {
  const units = useSettingsStore().systemOfUnits
  // console.log('@@@ UPDATE PRODUCT MATRIX', product.PID, units)
  const regimes = product.props.regime.value
  if (!Array.isArray(regimes)) return
  const body = { units }
  if (regimes.includes('heating'))
    body.heating = { gradients: CALCULATORS_PARAMS_VALUES.heatingGradient }
  if (regimes.includes('cooling'))
    body.cooling = { gradients: CALCULATORS_PARAMS_VALUES.coolingGradient }
  const response = await restClient(
    'toolbox',
    'POST',
    `toolbox/products/${product.PID}/matrix`,
    body,
    { timeout: 90000 }
  )
  if (response.code !== 200) return

  const lengths = response.data.lengths
  const matrix = response.data.matrix

  matrix.forEach(variant => {
    // console.log('MATRIX FOR VARIANT', variant)
    const vId = _variantId(variant.dimensions)
    if (!product.variants[vId]) return

    let vM
    regimes.forEach(regime => {
      const valid =
        variant[regime] &&
        !Object.keys(variant[regime]).some(
          gradient => 'error' in variant[regime][gradient]
        )
      if (!valid) return
      if (!vM) vM = {}
      vM[regime] = variant[regime]
    })
    if (vM) Object.assign(product.variants[vId], { _matrix: vM })
  })
  _updateProductPowers(product, product)
  // console.log('@@@ UPDATED PRODUCT', product.PID, 'MATRIX', product)
}

function _calculateProductPower(
  product,
  variantId,
  length,
  regime,
  gradient,
  fanSpeedIndex,
  units
) {
  // console.log(
  //   'CALCULATE',
  //   product.PID,
  //   variantId,
  //   length,
  //   regime,
  //   gradient,
  //   fanSpeedIndex
  // )
  if (!product.variants[variantId]) return
  if (!product.variants[variantId]._matrix) return
  if (!product.variants[variantId]._matrix[regime]) return
  if (!product.variants[variantId]._matrix[regime][gradient]) return
  const powers = product.variants[variantId]._matrix[regime][gradient]
  const standardLengths = product.props.length && product.props.length.discrete
  if (!Array.isArray(standardLengths) || standardLengths.length === 0) return
  if (standardLengths.length === 1) return powers[0][fanSpeedIndex]

  // Check if length is a standard one
  const lengthIndex = standardLengths.findIndex(l => l === length)
  // console.log('LENGTH INDEX of', length, 'is', lengthIndex)
  // console.log('POWERS', powers)
  if (lengthIndex >= 0)
    return powers[lengthIndex] && powers[lengthIndex][fanSpeedIndex]

  // Find standard lengths around the length
  let prevIndex, postIndex
  if (length < standardLengths[0]) {
    prevIndex = 0
    postIndex = 1
  } else if (length > standardLengths[standardLengths.length - 1]) {
    postIndex = standardLengths.length - 1
    prevIndex = postIndex - 1
  } else {
    postIndex = standardLengths.findIndex(l => l > length)
    prevIndex = postIndex - 1
  }

  const prevPwr = powers[prevIndex][fanSpeedIndex]
  const postPwr = powers[postIndex][fanSpeedIndex]
  const prevLen = standardLengths[prevIndex]
  const postLen = standardLengths[postIndex]

  const b = (postPwr - prevPwr) / (postLen - prevLen)
  return prevPwr + k * (length - prevLen)
}

function _updateProductPowers(product, originalProduct) {
  // console.log('@@@PP UPDATE PRODUCT POWERS', product.PID)
  const settingsStore = useSettingsStore()
  const gradients = {
    heating: settingsStore.calculatorsParams.heatingGradient,
    cooling: settingsStore.calculatorsParams.coolingGradient,
  }
  const fanSpeedIndex = CALCULATORS_PARAMS_VALUES.fanSpeed.findIndex(
    speed => speed === settingsStore.calculatorsParams.fanSpeed
  )
  const standardLengths = product.props.length && product.props.length.discrete
  if (!Array.isArray(standardLengths) || standardLengths.length === 0) return
  const maxLength = standardLengths[standardLengths.length - 1]
  const minLength = standardLengths[0]
  const hasFan = !!(product.props.fan && product.props.fan.value)
  const fanIdx = hasFan ? fanSpeedIndex : 0
  // console.log(
  //   '@@@PP',
  //   minLength,
  //   maxLength,
  //   standardLengths,
  //   hasFan,
  //   fanSpeedIndex,
  //   fanIdx
  // )

  // console.log('@@PP variants', product.PID, Object.keys(product.variants))
  const res = Object.keys(product.variants).reduce(
    (acc, vId) => {
      ;['heating', 'cooling'].forEach(regime => {
        const pwrMax = _calculateProductPower(
          originalProduct,
          vId,
          maxLength,
          regime,
          gradients[regime],
          fanIdx,
          settingsStore.systemOfUnits
        )
        const pwrMin = _calculateProductPower(
          originalProduct,
          vId,
          minLength,
          regime,
          gradients[regime],
          fanIdx,
          settingsStore.systemOfUnits
        )
        // console.log('@@PP POWER', product.PID, vId, regime, pwrMin, pwrMax)
        if (pwrMax) {
          if (!acc[1][regime]) acc[1][regime] = pwrMax
          else if (acc[1][regime] < pwrMax) acc[1][regime] = pwrMax
        }
        if (pwrMin) {
          if (!acc[0][regime]) acc[0][regime] = pwrMin
          else if (acc[0][regime] > pwrMin) acc[0][regime] = pwrMin
        }
        if (pwrMin && pwrMax) {
          if (!acc[2][vId]) acc[2][vId] = {}
          acc[2][vId][regime] = [pwrMin, pwrMax]
        }
      })
      return acc
    },
    [{}, {}, {}]
  )

  // console.log('@@@PP - POWER RESULT', product.PID, res)

  const units = useSettingsStore().systemOfUnits
  const update = {
    power: {
      min: res[0],
      max: res[1],
      maxPerMeter: Object.keys(res[1]).reduce(
        (acc, r) => (
          (acc[r] = isNaN(res[1][r])
            ? null
            : units === 'imperial'
            ? Math.round((res[1][r] * 12) / maxLength)
            : Math.round((res[1][r] * 1000) / maxLength)),
          acc
        ),
        {}
      ),
      variants: res[2],
    },
  }
  // console.log('POWER UPDATE', update)
  Object.assign(product, update)
  // console.log(
  //   '@@@PP UPDATE PRODUCT POWERS - RESULT',
  //   product.PID,
  //   product.power
  // )
}
