<template>
  <component :is="layout" ref="layoutElement">
    <router-view />
  </component>
  <LayoutFooter v-if="!noFooter" />
  <FlashContainer />
  <InstallBanner />
  <LayoutMenu />
</template>

<script>
export default {
  name: 'Layout',
}
</script>

<script setup>
import { ref, markRaw, watch, onMounted, onBeforeUnmount } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import DefaultLayout from '@/layouts/default.vue'
import FlashContainer from 'mc-gcommon/components/layout/flash-container.vue'
import InstallBanner from 'mc-gcommon/components/layout/install-banner.vue'
import LayoutMenu from '@/components/layout/layout-menu.vue'
import LayoutFooter from 'mc-gcommon/components/layout/layout-footer.vue'

const route = useRoute()
const router = useRouter()

const layout = ref(null)
const noFooter = ref(false)

watch(
  () => route.meta.layout,
  async metaLayout => {
    try {
      const component =
        metaLayout && (await import(`../../layouts/${metaLayout}.vue`))
      layout.value = markRaw(component.default || DefaultLayout)
    } catch (e) {
      layout.value = markRaw(DefaultLayout)
    }
  },
  { immediate: true },
)

watch(
  () => route.meta.noFooter,
  async nf => {
    noFooter.value = !!nf
  },
  { immediate: true },
)

function getScrollParent(element, includeHidden) {
  // console.log('LAYOUT GET SCROLL PARENT', element)
  var style = getComputedStyle(element)
  var excludeStaticParent = style.position === 'absolute'
  var overflowRegex = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/

  if (style.position === 'fixed') return document.body
  for (var parent = element; (parent = parent.parentElement); ) {
    style = getComputedStyle(parent)
    if (excludeStaticParent && style.position === 'static') {
      continue
    }
    if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX))
      return parent
  }

  return document.body
}

const layoutElement = ref(null)
const scrollElement = ref(null)
const scrollHistory = {}

router.afterEach((to, from) => {
  if (!from) return
  // console.log('*** BEFORE', layoutElement.value && layoutElement.value.$el)
  //   console.log(
  //     'LAYOUT BEFORE EACH',
  //     to,
  //     from,
  //     'layoutElement',
  //     layoutElement.value.$el
  //   )

  if (!scrollElement.value && layoutElement.value && layoutElement.value.$el)
    scrollElement.value = getScrollParent(layoutElement.value.$el)
  if (scrollElement.value) {
    // console.log('*** LAYOUT BEFORE EACH', from.name, scrollElement.value)
    const scrollTop = scrollElement.value.scrollTop
    // console.log('*** - scrollTop', scrollTop)
    scrollHistory[from.name] = scrollTop
  }
})

// router.afterEach((to, from) => {
//   console.log(
//     '*** AFTER',
//     layoutElement.value && layoutElement.value.$el,
//     to.name
//   )
// })

// onMounted(() => {
//   console.log('*** ON MOUNTED')
// })

// onBeforeUnmount(() => {
//   console.log('*** ON BEFORE UNMOUNT')
// })
</script>
