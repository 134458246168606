import { defineStore } from 'mc-gcommon/utils/pinia'
import { useFlashStore } from 'mc-gcommon/stores/flash.js'
import { useProductsStore } from './products.js'
import { useConversationsStore } from './conversations.js'
import { idbToolbox } from '../utils/idb-toolbox.js'
import { definedMeasurementSystems } from '../utils/measurement-systems.js'

const STORE = 'settings'

export const CALCULATORS_PARAMS_VALUES = {
  heatingGradient: [60, 50, 40, 30, 20],
  coolingGradient: [-20, -15, -10, -5],
  fanSpeed: ['off', 'low', 'medium', 'high']
}

// // default state values
// const STATE_OFFLINE_CONTENT_DEFAULT = {
//   productData: {
//     offline: 'permanent'
//   },
//   productImages: {
//     offline: true
//   },
//   calcData: {
//     offline: true
//   },
//   conversations: {
//     offline: true
//   }
// }

const STATE_CALCULATORS_DEFAULT = {
  heatingGradient: CALCULATORS_PARAMS_VALUES.heatingGradient[1],
  coolingGradient: CALCULATORS_PARAMS_VALUES.coolingGradient[1],
  fanSpeed: CALCULATORS_PARAMS_VALUES.fanSpeed[2]
}

export const useSettingsStore = defineStore({
  id: STORE,

  state: () => ({
    // offlineContent: { ...STATE_OFFLINE_CONTENT_DEFAULT },
    systemOfUnits: definedMeasurementSystems[0],
    calculatorsParams: { ...STATE_CALCULATORS_DEFAULT }
  }),

  getters: {
    // isContentOffline: state => content =>
    //   (state.offlineContent[content] &&
    //     !!state.offlineContent[content].offline) ||
    //   false,
    getFanSpeedIndex: state =>
      CALCULATORS_PARAMS_VALUES.fanSpeed.findIndex(
        speed => speed === state.calculatorsParams.fanSpeed
      )
  },

  actions: {
    async init() {
      // console.log('SETTINGSSTORE INIT')
      try {
        this.$patch({
          // offlineContent: (await idbToolbox.settingsOfflineContentGet()) || {},
          calculatorsParams:
            (await idbToolbox.settingsCalculatorParamsGet()) || {},
          systemOfUnits:
            (await idbToolbox.settingsSystemOfUnitsGet()) ||
            definedMeasurementSystems[0]
        })
        await Promise.all([
          // idbToolbox.settingsOfflineContentPut(this.offlineContent),
          idbToolbox.settingsCalculatorParamsPut(this.calculatorsParams),
          idbToolbox.settingsSystemOfUnitsPut(this.systemOfUnits)
        ])
      } catch (err) {
        console.error(err)
      }
    },

    // async enableOfflineContent(param, enable) {
    //   console.log(
    //     'ENABLE OFFLINE CONTENT',
    //     param,
    //     enable,
    //     this.offlineContent[param]
    //   )
    //   if (!(param in this.offlineContent)) return
    //   if (this.offlineContent[param].offline === 'permanent') return
    //   this.offlineContent[param].offline = !!enable
    //   try {
    //     await idbToolbox.settingsOfflineContentPut(this.offlineContent)
    //   } catch (err) {
    //     console.error(err)
    //   }

    //   const productsStore = useProductsStore()
    //   const conversationsStore = useConversationsStore()
    //   await productsStore.offlineSettingsChanged(param, enable)
    //   await conversationsStore.offlineSettingsChanged(param, enable)
    // },

    async setCalculatorsParam(param, value) {
      // console.log('SET CALCULATORS PARAM', param, value)
      if (!(param in this.calculatorsParams)) return
      if (!CALCULATORS_PARAMS_VALUES[param].includes(value)) return
      if (this.calculatorsParams[param] === value) return
      this.calculatorsParams[param] = value
      try {
        await idbToolbox.settingsCalculatorParamsPut(this.calculatorsParams)
      } catch (err) {
        console.error(err)
      }

      const productsStore = useProductsStore()
      await productsStore.calculatorsParamsChanged(param, value)
    },

    async setSystemOfUnits(sou) {
      if (this.systemOfUnits === sou) return
      this.systemOfUnits = sou
      try {
        await idbToolbox.settingsSystemOfUnitsPut(this.systemOfUnits)
      } catch (err) {
        console.error(err)
      }

      const productsStore = useProductsStore()
      await productsStore.systemOfUnitsChanged(this.systemOfUnits)
    }
  }
})
